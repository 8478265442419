// Libraries
import React from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import TitleBlock from 'marketing/components/TitleBlock';

// Styles
import { layoutContainer, listingContainer } from './styles.module.css';

export default (data) => {
  const {
    heading,
    subheading,
    heroImage,
    listPrizes,
    winnersLink,
  } = data.pageContext.data;

  return (
    <LayoutContainer
      includeOrderTicketsCta
      includeWinnersLink={winnersLink}
    >

      <div className="theme-background-primary">
        <div className={layoutContainer}>
          <TitleBlock
            mainHeading={heading}
            subHeading={subheading}
            onThemeBackground
          />
          <img className="full-size-img u-block l-margin-top--40" src={heroImage} alt=" " />
          <div className={listingContainer}>
            <div
              className="l-col-offset-2 l-col-8 l-margin u-text-align-center wysiwyg"
              dangerouslySetInnerHTML={{ __html: listPrizes }}
            />
          </div>
        </div>
      </div>

    </LayoutContainer>
  );
};
